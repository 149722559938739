<template>
    <div>
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="#3F033B"
        spinner="bar-fade-scale"
      />
      <ServerError v-if="ServerError" />
      <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">
              {{ msg }}
            </span>
          </v-flex>
  
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: #000">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-layout wrap>
        <v-flex xs12 sm3 md6 text-left>
          <span class="mainHeader">GOLD SCHEME</span>
        </v-flex>
        <v-flex xs12 sm3 md2 text-right>
          <v-text-field
            background-color="white"
            v-model="keyword"
            color="#b234a9"
            clearable
            hide-details
            placeholder="Search"
            outlined
            class="rounded-xl"
            dense
            append-icon="mdi-magnify"
          >
          </v-text-field>
        </v-flex>
        <v-flex xs12 sm3 md2 class="buttons1" pt-2 pt-sm-0 text-right px-1>
          <v-btn dark block color="#3F053C" @click="addScheme = true" class="button1">
            <v-icon small>mdi-plus</v-icon> &nbsp; New Scheme
          </v-btn>
        </v-flex>
        <v-flex xs12 sm3 md2 class="buttons1" pt-2 pt-sm-0 text-right >
          <v-btn dark block color="#3F053C"  class="buttons1"  @click="$router.push('/CustomerSchemeList')"> Enlisting
          </v-btn>
        </v-flex>
        <v-flex xs12 py-6 text-left>
          <v-card
            class="rounded-lg"
            elevation="0"
            :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
            :flat="$route.name == 'Dashboard' ? true : false"
          >
            <v-layout wrap pa-4 v-if="schemeList">
              <v-flex xs12 v-if="schemeList.length>0">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left tablefont"><b>S.No.</b></th>
                        <th class="text-left tablefont"><b>Scheme Name</b></th>
                        <th class="text-left tablefont"><b>Monthly Deposit(₹)</b></th>
                        <th class="text-left tablefont"><b>Yearly Interest(%)</b></th>
                        <th class="text-left tablefont"><b>Monthly Interest(%)</b></th>
                        <th class="text-left tablefont"><b>Actions</b></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(value, i) in schemeList"
                        :key="i"
                        class="table"
                      >
                        <!-- <td>{{ i + 1 }}</td> -->
                        <td>
                          <span v-if="page == 1">
                            {{ i + 1 }}
                          </span>
                          <span v-else>
                            {{ i + 1 + 20 * (page - 1) }}
                          </span>
                        </td>
                        <td>
                          <span v-if="value.goldSchemeName">{{
                            value.goldSchemeName
                          }}</span>
                          <span v-else>-</span>
                        </td>
                        <td>
                          <span v-if="value.minmonthlydeposit">{{
                            value.minmonthlydeposit
                          }}</span>
                          <span v-else>-</span>
                        </td>
                        <td>
                          <span v-if="value.yearlyIntrest">{{
                            value.yearlyIntrest
                          }}</span>
                          <span v-else>-</span>
                        </td>
                        <td>
                          <span v-if="value.monthlyIntrest">{{
                            value.monthlyIntrest.toFixed(4)
                          }}</span>
                          <span v-else>0</span>
                        </td>
                        <td>
                          <v-layout wrap>
                            <!-- <v-flex xs4>
                              <v-icon
                            size="18"
                            color="#3F053C"
                            style="cursor: pointer"
                            @click="
                              $router.push('/SupplierDetail?id=' + value._id)
                            "
                            >mdi-eye</v-icon
                          >
                            </v-flex> -->
                            <v-flex xs4 px-1>
                              <v-icon
                            size="18"
                            color="#3F053C"
                            style="cursor: pointer"
                            @click="(curItem = value), (editdialogue = true)"
                            >mdi-pencil</v-icon
                          >
                            </v-flex>
                            <v-flex xs4>
                              <v-icon
                            size="18"
                            color="#3F053C"
                            style="cursor: pointer"
                            @click="(deletedialog = true), (curId = value._id)"
                            >mdi-delete</v-icon
                          >
                            </v-flex>
                          </v-layout>
                          
                         
  
                         
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-flex>
              <v-flex xs12 v-else pa-4 text-center class="tablefont1">
                No data found
              </v-flex>
            </v-layout>
          </v-card>
          <v-layout wrap v-if="schemeList">
            <v-flex xs12 pt-4 v-if="schemeList.length>0">
              <v-pagination
                small
                color="#3F053C"
                v-model="page"
                :length="Pagelength"
              >
              </v-pagination>
            </v-flex>
          </v-layout>
          <v-dialog width="900px" v-model="addScheme">
            <v-form v-model="addcat" ref="addcat" @submit.prevent>
              <v-card tile width="1000px" class="px-8 py-4 rounded-lg">
               
                <v-layout wrap justify-center>
                <v-flex xs11 text-center><span style="color:#3F053C;" class="menuhead1">New Scheme</span></v-flex>
                <v-flex xs1 text-right>
                  <v-icon
                  @click="(addScheme = false)(resetForm())"
                    color="#3F053C"
                    >mdi-close-box</v-icon
                  ></v-flex
                >
                <v-flex xs8 text-center py-4 >
              <v-divider></v-divider>
            </v-flex>
              </v-layout>
                <v-layout wrap pb-4>
                  <v-flex xs4>
                    <v-layout wrap px-4>
                      <v-flex xs12 text-left>
                        <span class="tablefont"> Scheme Name </span>
                      </v-flex>
                      <v-flex xs12>
                        <v-text-field
                          :rules="[rules.required]"
                          type="text"
                          placeholder="Enter Scheme Name"
                          v-model="schemeName"
                          outlined
                          dense
                          color="#b234a9"
                          hide-details="auto"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs4>
                    <v-layout wrap px-4>
                      <v-flex xs12 text-left>
                        <span class="tablefont">Yearly Interest</span>
                      </v-flex>
                      <v-flex xs12>
                        <v-text-field
                          :rules="[rules.required]"
                          type="Number"
                          placeholder="Enter Yearly Interest"
                          v-model="yearlyInterest"
                          outlined
                          dense
                          color="#b234a9"
                          hide-details="auto"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs4>
                    <v-layout wrap px-4>
                      <v-flex xs12 text-left>
                        <span class="tablefont"> Monthly Deposit </span>
                      </v-flex>
                      <v-flex xs12>
                        <v-text-field
                          :rules="[rules.required]"
                          type="Number"
                          placeholder="Enter Monthly Deposit"
                          v-model="monthlyDeposit"
                          outlined
                          dense
                          color="#b234a9"
                          hide-details="auto"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-layout wrap pb-4 justify-end>
                <v-spacer></v-spacer>
                  <v-flex xs3 text-right>
                    <v-layout wrap px-4 justify-end fill-height>
                      <v-flex xs12 text-center align-self-end>
                        <v-btn
                          type="submit"
                          color="#3F053C"
                          
                          dark small
                          block
                          @click="AddScheme()"
                          ><v-icon>mdi-plus</v-icon>Add</v-btn
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-form>
          </v-dialog>
          <v-dialog width="400px" v-model="editdialogue">
            <v-form v-model="editcat" ref="editcat" @submit.prevent>
            <v-card width="800px" class="pa-4">
              
              <v-layout wrap justify-center>
                <v-flex xs11 text-center><span style="color:#3F053C;" class="menuhead1">Edit Scheme</span></v-flex>
                <v-flex xs1 text-right>
                  <v-icon
                  @click="(editdialogue = false)(resetForm2())"
                    color="#3F053C"
                    >mdi-close-box</v-icon
                  ></v-flex
                >
                <v-flex xs8 text-center py-4 >
              <v-divider></v-divider>
            </v-flex>
              </v-layout>
                  <v-layout wrap>
                    <v-flex xs12 px-4 text-left class="tablefont">Scheme Name </v-flex>
                    <v-flex xs12 px-4>
                      <v-text-field
                        color="#b234a9"
                        placeholder="Name"
                        v-model="curItem.goldSchemeName"
                        outlined type="text"
                        :rules="[rules.required]"
                        dense
                        hide-details="auto"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 px-4 pt-4 text-left class="tablefont">Yearly Interest</v-flex>
                    <v-flex xs12 px-4>
                      <v-text-field
                        color="#b234a9"
                        v-model="curItem.yearlyIntrest"
                        dense
                        :rules="[rules.required]"
                          type="Number"
                          placeholder="Enter Yearly Interest"
                        outlined
                        hide-details="auto"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 px-4 pt-4 text-left class="tablefont"
                      >Monthly Deposit</v-flex
                    >
                    <v-flex xs12 px-4>
                      <v-text-field
                        v-model="curItem.minmonthlydeposit"
                        dense
                        color="#b234a9"
                        placeholder="Enter Monthly Deposit"
                        outlined
                         :rules="[rules.required]"
                          type="Number"
                        hide-details
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
              <v-card-actions class="pt-3">
                <v-spacer></v-spacer>
                <!-- <v-btn
                  color="grey"
                  text
                  class="body-2 font-weight-bold"
                  @click="(editdialogue = false)(resetForm2())"
                  >Cancel</v-btn
                > -->
                <v-btn
                  color="#3f053c"
                  class="body-2 font-weight-bold"
                  dark
                  @click="editScheme()"
                  >OK</v-btn
                >
              </v-card-actions>
            </v-card>
            </v-form>
          </v-dialog>
          <v-dialog width="400px" v-model="deletedialog">
            <v-card width="400px" class="pa-2">
             
              <v-layout wrap justify-center>
                <v-flex xs11 text-center><span style="color:#3F053C;" class="menuhead1">Delete</span></v-flex>
                <v-flex xs1 text-right>
                  <v-icon
                  @click="deletedialog = false"
                    color="#3F053C"
                    >mdi-close-box</v-icon
                  ></v-flex
                >
                <v-flex xs8 text-center py-4 >
              <v-divider></v-divider>
            </v-flex>
              </v-layout>
              <v-card-text class="pa-4 tablefont"
                >Are you sure you want to delete this scheme?
              </v-card-text>
              <v-card-actions class="pt-3">
                <v-spacer></v-spacer>
                <!-- <v-btn
                  color="grey"
                  text
                  class="body-2 font-weight-bold"
                  @click="deletedialog = false"
                  >Cancel</v-btn
                > -->
                <v-btn
                  color="#3F053C"
                  class="button1"
                  dark
                  @click="deleteScheme()"
                  >OK</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-flex>
      </v-layout>
    </div>
  </template>
  <script>
  import axios from "axios";
  export default {
    data() {
      return {
        appLoading: false,
        ServerError: false,
        showsnackbar: false,
        timeout: 2000,
        msg: null,
        page: 1,
        Pagelength: null,
        addcat: false,
        editcat: false,
        // form:false,
        // valid: false,
        jewelleryId: null,
        schemeName: null,
        yearlyInterest: null,
        monthlyDeposit: null,
        rules: {
          required: (value) => !!value || "Required.",
          counter: (value) => value.length <= 20 || "Max 20 characters",
  
          email: (value) => {
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
        },
        keyword: null,
        addScheme: false,
        schemeList: [],
        curItem: [],
        curId: null,
        editdialogue: false,
        deletedialog: false,
      };
    },
    mounted() {
      this.getSchemeList();
    },
    computed: {
      appUser() {
        return this.$store.state.userData;
      },
    },
    watch: {
      keyword() {
        this.getSchemeList();
      },
      page(){
        this.getSchemeList();
      },
    },
    methods: {
      resetForm() {
        this.$refs.addcat.reset();
      },
      resetForm2() {
        this.$refs.editcat.reset();
        this.getSchemeList();
      },
      getSchemeList() {
        this.appLoading = true;
        axios({
          method: "get",
          url: "/goldscheme/getlist",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          params: {
            keyword: this.keyword,
            page: this.page,
            count: 20,
          },
        })
          .then((response) => {
            this.appLoading = false;
            if (response.data.status == true) {
              this.schemeList = response.data.data;
              this.Pagelength = Math.ceil(response.data.count / 20);
            } else {
              this.snackbar = true;
              this.msg = response.data.msg;
            }
  
            // this.msg = response.data.msg;
  
            // this.snackbar = true;
          })
          .catch((err) => {
            this.appLoading = false;
            console.log(err);
          });
      },
   
      AddScheme() {
        if (this.$refs.addcat.validate()) {
          axios({
            url: "/goldscheme/add",
            method: "POST",
            data: {
                goldSchemeName : this.schemeName,
                minmonthlydeposit : this.monthlyDeposit,
                yearlyIntrest: this.yearlyInterest,
                // monthlyIntrest : this.monthlyIntrest,
            },
            headers: {
              "x-auth-token": localStorage.getItem("token"),
            },
          })
            .then((response) => {
              this.appLoading = false;
              if (response.data.status == true) {
                this.msg = response.data.msg;
                this.showsnackbar = true;
                this.addScheme = false;
                this.$refs.addcat.resetValidation();
                this.schemeName="",
              this.monthlyDeposit=null;
             this.yearlyInterest=null;
                this.getSchemeList();
              } else {
                this.msg = response.data.msg;
                this.showsnackbar = true;
              }
            })
            .catch((err) => {
              this.appLoading = false;
              this.ServerError = true;
              console.log(err);
            });
        }
      },
      editScheme() {
        if (this.$refs.editcat.validate()) {
        axios({
          url: "/goldscheme/edit",
          method: "POST",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          data: {
            id: this.curItem._id,
            yearlyIntrest: this.curItem.yearlyIntrest,
            goldSchemeName : this.curItem.goldSchemeName,
            minmonthlydeposit : this.curItem.minmonthlydeposit,
          },
        })
          .then((response) => {
            this.editdialogue = false;
            this.appLoading = false;
            if (response.data.status == true) {
              this.msg = response.data.msg;
              this.showsnackbar = true;
              
              this.$refs.editcat.resetValidation();
              this.getSchemeList();
            } else {
              this.msg = response.data.msg;
              this.showsnackbar = true;
              this.getSchemeList();
  
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
        }
      },
      deleteScheme() {
        axios({
          url: "/goldscheme/delete",
          method: "GET",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          params: {
            id: this.curId,
          },
        })
          .then((response) => {
            this.deletedialog = false;
            this.appLoading = false;
            if (response.data.status == true) {
              this.msg = response.data.msg;
              this.showsnackbar = true;
              this.getSchemeList();
            } else {
              this.msg = response.data.msg;
              this.showsnackbar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
    },
  };
  </script>
  <style scoped>
  .v-text-field input {
    font-family: kumbhBold;
  }
  </style>
  